<script>
import AuthTemplate from '../AuthTemplate.vue'
import { required, email } from 'vuelidate/lib/validators'
import InputField from '@/components/general/InputField'
export default {
  name: 'RecoveryPage',
  components: {
    AuthTemplate,
    InputField
  },
  data: () => ({
    theme: '',
    responseStatus: '',
    formData: {
      email: null
    },
    availableThemes: [
      { themeName: 'empregosagro', color: 'empregosagro', logo: '/assets/images/customers/empregosagro.svg', secondary: '#62B55A' },
      { themeName: 'empregatransporte', color: 'empregatransporte', logo: '/assets/images/customers/sestsenat.svg', secondary: '#2e72e0' },
      { themeName: 'vivae', color: 'vivae', logo: '/assets/images/customers/viva-e.png', secondary: '#72009C' },
      { themeName: 'portalfenacon', color: 'portalfenacon', logo: '/assets/images/customers/portalfenacon.png', secondary: '#4196B7' },
      { themeName: 'pwfglobal', color: 'pwfglobal', logo: '/assets/images/customers/wfglobal-logo.png', secondary: '#AA2721' },
      { themeName: 'acats', color: 'acats', logo: '/assets/images/customers/acats.png', secondary: '#2D5AA4' },
      { themeName: 'acate', color: 'acate', logo: '/assets/images/customers/acate-verde.png', secondary: '#2D5AA4' },
      { themeName: 'coursera', color: 'coursera', logo: '/assets/images/customers/coursera_white.png', secondary: '#2D5AA4' },
      { themeName: 'govsp', color: 'govsp', logo: '/assets/images/customers/govsp-white.png', secondary: '#E81B1B' },
      { themeName: 'govmt', color: 'govmt', logo: '/assets/images/customers/govmt-white.png', secondary: '#6B6C6F' },
      { themeName: 'govmg', color: 'govmg', logo: '/assets/images/customers/govmg-white.png', secondary: '#D9A244' },
      { themeName: 'goves', color: 'goves', logo: '/assets/images/customers/goves-white.png', secondary: '#32114B' },
      { themeName: 'senacsc', color: 'senacsc', logo: '/assets/images/customers/senacsc-white.png', secondary: '#E49435' },
      { themeName: 'decolaentregadores', color: 'decolaentregadores', logo: '/assets/images/customers/decolaentregadores-white.png', secondary: '#D92E3B' }
    ]
  }),
  validations: {
    formData: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    resetPassword () {
      const params = {
        email: this.formData.email,
        language: window.navigator.languages.includes('pt') ? 'pt' : 'en',
        theme: this.theme
      }
      this.$store.dispatch('attemptResetUserPassword', params).then(response => {
        this.responseStatus = response.status
      }).catch(err => {
        this.responseStatus = err.response.status
      })
    }
  },
  created () {
    this.appliedTheme = Object.prototype.hasOwnProperty.call(this.$route.query, 'theme') && this.availableThemes.filter(theme => theme.themeName === this.$route.query.theme)[0]
    if (this.$route.query.theme) this.theme = '?theme=' + this.$route.query.theme
    // eslint-disable-next-line no-undef
    zE('webWidget', 'hide')
  },
  beforeRouteLeave (to, from, next) {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'show')
    next()
  }
}
</script>
<template>
  <auth-template :color="appliedTheme.color? appliedTheme.color : 'defaultLogin'"  :logo="appliedTheme.logo" :title="$t('global:welcome')" :subtitle="$t('login.page:subtitle')">
    <template v-slot:image>
      <img :src="appliedTheme.color ? `/assets/images/customers/bg-${appliedTheme.color}.png` : '/assets/images/customers/plooral-hand.png'" height="100%" />
    </template>
    <template class="label-custom-wrapper" v-slot:form>
      <div class="recovery--container">
      <v-card class="recovery-form--wrapper">
      <v-btn text class="recovery-form--button lato bold"  :color="appliedTheme.secondary" :to="`/login${theme}`">
          <v-icon class="recovery-form--button-chevron">mdi-chevron-left</v-icon>
          {{$t('global:back')}}
        </v-btn>
      <h4>{{ $t('recovery.form:title') }}</h4>
      <p class="recovery-form-subtitle" v-html="$t('recovery.form:subtitle', { registrationUrl: '/register' })" />
      <div v-if="responseStatus === 204" class="response-area-success">{{$t('recovery.form.success')}}</div>
      <div v-else-if="responseStatus !== 204  && responseStatus !== ''" class="response-area-error">{{responseStatus === 500? $t('recovery.form.error') : $t('recovery.form.default.error')}}</div>
      <input-field
        class="mt-10"
        outlined
        v-model="formData.email"
        :label="$t('registration.form:email')"
        :validation="$v.formData.email"
        :hide-validation="!$v.formData.email.$error && isMobile"
      />
      <v-btn class="mt-10 btn bold transform-unset" style="color: white; font-size: 16px;" :color="appliedTheme.secondary ? appliedTheme.secondary : '#1200D3'" @click="resetPassword" :disabled="$v.$invalid" large block>{{ $t('global:send') }}</v-btn>
      </v-card>
      </div>
    </template>
  </auth-template>
</template>
<style lang="scss">
  .response-area-error {
    color: #a94442;
    background-color: #f2dede;
    border: none;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    padding: 15px;
    border-radius: 4px;
  }
  .response-area-success {
    color: #3c763d;
    background-color: #dff0d8;
    border: none;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    padding: 15px;
    border-radius: 4px;
  }
  .recovery--container {
    max-width: 100%;
    width: 515px;

    .v-input .v-label {
      color: rgba(0,0,0,.6) !important;
    }

    .recovery-form--wrapper {
      padding: 45px 64px;
      border-radius: 10px;
      text-align: left;
      .recovery-form-subtitle {
        @extend .subtitle--1;
        @extend .neutral-medium;
      }
      .recovery-form--button {
        color: $primary-medium;
        padding-left: 0;
        .recovery-form--button-chevron {
          margin-right: 10px;
          margin-top: 2px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .recovery-form--wrapper {
        border-radius: 10px 10px 0 0;
        padding: 36px 24px 80px;
      }
    }
  }
</style>
